import { NextPage } from "next";
import { useAccessToken } from "@shared/hooks/use-access-token";
import { useEffect } from "react";
import { useRedirectAfterLogin } from "@shared/hooks/use-redirect-after-login";
import { useRouter } from "next/router";
import { useSsoContext } from "@shared/contexts/sso";

const Login: NextPage = () => {
  const router = useRouter();
  const sso = useSsoContext();
  const [, setRedirect] = useRedirectAfterLogin();
  const [accessToken, setAccessToken] = useAccessToken();

  useEffect(() => {
    if (router.isReady) {
      if ("access_token" in router.query) {
        setAccessToken(router.query.access_token as string);
      } else if (!accessToken) {
        setRedirect((router.query.redirect as string) || undefined);
        window.location.href = `${sso.baseUri}?client=${sso.client}`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  return null;
};

export default Login;
